import './App.css';
import LeftColumn from './components/LeftColumn/LeftColumn';
import RightColumn from './components/RightColumn/RightColumn';
import { useEffect } from 'react';

function App() {


  useEffect(() => {
    const updateSpotlightPosition = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    document.body.style.setProperty('--x', `${x}px`);
    document.body.style.setProperty('--y', `${y}px`);
    };

    // Add event listener when component mounts
    document.addEventListener('mousemove', updateSpotlightPosition);

    // Clean up the listener when the component is unmounted
    return () => {
        document.removeEventListener('mousemove', updateSpotlightPosition);
    };
  }, []);


  return (
    <div className="container">
      <div className="left-column">
        <LeftColumn />
      </div>
      <div className="right-column">
        <RightColumn />
      </div>
    </div>
  );
}

export default App;
