import React from 'react';
import './LeftColumn.css';
import Title from '../Title/Title';

function LeftColumn() {
    return (
        <div>
            <Title 
                title="Easy Chatbots"
                subtitle="Simplify the creation and maintenance of your chatbots!"
                text="Easily build, modify, and train chatbots that your clients can interact with. They can
                answer questions, provide information, and even be customized to perform specific tasks."/>
        </div>
    );
}

export default LeftColumn;