import React from 'react';
import './RightColumn.css';

import Title from '../Title/Title';

function RightColumn() {
    return (
        <div>
        <Title 
            title="This project is a work in progress"
            subtitle="Expected Release: April 2024"
            text="We are building a platform that will allow companies to
            create custom Chatbots with as little effort as possible."/>
        </div>
    );
}

export default RightColumn;