import React from 'react';

import './Title.css';

function Title( {title, subtitle, text}) {
    return (
        <div className="title">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
            <p>{text}</p>
        </div>
    );
}

export default Title;